<template>
	<v-layout column fill-height>
		<v-flex display-1 my-3 shrink text-xs-center v-text="$t('settings.title_company', { company: vendor.company })" />
		<v-flex scroll-y>
			<CustomerDetails />
		</v-flex>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import SettingsModuleGuard from '@/mixins/ModulesGuards/Settings/SettingsModuleGuard'

export default {
	name: 'CompanySettings',
	components: {
		CustomerDetails: () => ({
			component: import('@/components/CustomersManager/CustomerDetails')
		})
	},
	mixins: [SettingsModuleGuard],
	computed: {
		...mapState({
			vendor: state => state.company.selected
		})
	},
	watch: {
		vendor: {
			handler: function () {
				this.setCompanyData()
			}
		}
	},
	created: function () {
		this.setCompanyData()
	},
	methods: {
		setCompanyData: function () {
			this.service.getCompany(this.vendor.id).then(companyData => {
				Object.entries(companyData).forEach(([key, value]) => {
					if (this.vendor.hasOwnProperty(key) && this.vendor[key] != value) {
						this.vendor[key] = value
					} else if (!this.vendor.hasOwnProperty(key)) {
						Vue.set(this.vendor, key, value)
					}
				})
			})
		}
	}
}
</script>
